@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.home_wrapper {
  height: auto;
  min-height: 80vh;
  width: 100%;
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(/static/media/bg.20a83f6c.svg);
  color: #fff;
}

.home_wrapper .home_container {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_wrapper .home_container .left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.home_wrapper .home_container .left .text_default_home {
  width: 80%;
  font-weight: 300;
  line-height: 25px;
}

.home_wrapper .home_container .left button {
  border: none;
  height: 50px;
  width: 250px;
  border-radius: 50px;
  background: #fff;
  color: #f50;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.home_wrapper .home_container .right {
  width: 50%;
}

.home_wrapper .home_container .right #Caminho_1056 {
  -webkit-animation: flash 3s ease-in-out infinite alternate;
          animation: flash 3s ease-in-out infinite alternate;
}

.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .home_container {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 150px 0 !important;
  }
  .home_container .left {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  .home_container .left .text_default_home {
    width: 80%;
    font-weight: 300;
    line-height: 25px;
  }
  .home_container .left .text_default_home h1 {
    line-height: 35px;
  }
  .home_container .left button {
    border: none;
    height: 50px;
    width: 250px;
    border-radius: 50px;
    background: #fff;
    color: #f50;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
  }
  .home_container .right {
    width: 50%;
    display: none;
  }
}

@media (min-width: 600px) and (max-width: 992px) {
  .home_container {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 150px 0 !important;
  }
  .home_container .left {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  .home_container .left .text_default_home {
    width: 80%;
    font-weight: 300;
    line-height: 25px;
  }
  .home_container .left .text_default_home h1 {
    line-height: 35px;
  }
  .home_container .left button {
    border: none;
    height: 50px;
    width: 250px;
    border-radius: 50px;
    background: #fff;
    color: #f50;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
  }
  .home_container .right {
    width: 50%;
    display: none;
  }
}
/*# sourceMappingURL=style.css.map */
.boxes_wrapper {
  height: auto;
  min-height: 80vh;
  width: 100%;
  background-color: #f7f6f4;
}

.boxes_wrapper .boxes_conatiner {
  width: 70%;
  margin: 0 auto;
  position: relative;
  top: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: row;
}

.boxes_wrapper .boxes_conatiner .box {
  height: 300px;
  width: 410px;
  background: #fff;
  margin: 0 10px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  font-weight: 300;
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.171);
}

@media only screen and (max-width: 600px) {
  .boxes_wrapper {
    height: auto;
    min-height: 80vh;
    width: 100%;
    background-color: #f7f6f4;
  }
  .boxes_wrapper .boxes_conatiner {
    width: 90% !important;
    margin: 0 auto;
    position: relative;
    top: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .boxes_wrapper .boxes_conatiner .box {
    height: 300px;
    width: 410px;
    background: #fff;
    margin: 0 10px 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    font-weight: 300;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.171);
  }
}

@media (min-width: 992px) and (max-width: 1366px) {
  .boxes_wrapper {
    height: auto;
    min-height: 80vh;
    width: 100%;
    background-color: #f7f6f4;
  }
  .boxes_wrapper .boxes_conatiner {
    width: 95% !important;
    margin: 0 auto;
    position: relative;
    top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .boxes_wrapper .boxes_conatiner .box {
    height: 300px;
    width: 410px;
    background: #fff;
    margin: 0 10px 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    font-weight: 300;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.171);
  }
}
/*# sourceMappingURL=style.css.map */
.slide_wrapper {
  height: auto;
  width: 100%;
  background: #fff;
  padding: 10vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.slide_wrapper .carousel {
  width: 50% !important;
  margin: 0 auto !important;
  margin-bottom: 5vh !important;
}

.slide_wrapper p {
  text-align: justify;
  width: 50%;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .slide_wrapper {
    height: auto;
    width: 100%;
    background: #fff;
    padding: 10vh 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .slide_wrapper .carousel {
    width: 80% !important;
    margin: 0 auto !important;
    margin-bottom: 5vh !important;
  }
  .slide_wrapper p {
    text-align: justify;
    width: 80% !important;
    margin: 0 auto;
  }
}
/*# sourceMappingURL=style.css.map */
.download_wrapper {
  height: auto;
  min-height: 30vh;
  background: -webkit-gradient(linear, left top, right top, from(#f50), to(#f50));
  background: linear-gradient(90deg, #f50, #f50);
  display: flex;
  align-items: center;
  justify-content: center;
}

.download_wrapper .download_container {
  width: 65%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  text-transform: uppercase;
}

.download_wrapper .download_container button {
  height: 50px;
  width: 250px;
  border-radius: 50px;
  border: none;
  color: #f50;
  font-weight: 700;
  text-transform: uppercase;
  margin: 20px 0;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s;
  background-color: #fff;
}
/*# sourceMappingURL=style.css.map */
.important_wrapper {
  height: auto;
  width: 70%;
  background-color: #fff;
  font-size: 10px;
  text-align: left;
  margin: 0 auto;
}

.important_wrapper .important_container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  font-weight: 300;
  text-align: left;
}

.important_wrapper .important_container .cont {
  width: 50%;
}

.important_wrapper .important_container .list_wrapper {
  width: 50%;
}
/*# sourceMappingURL=style.css.map */
.footer_wrapper {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #f7f6f4;
    font-size: 11px;
}
.menu_wrapper {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  -webkit-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
  z-index: 9999;
}

.menu_wrapper .menu_container {
  width: 70%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu_wrapper .menu_container .logo_wrapper .logo {
  content: url(/static/media/logo.5dfde33a.svg);
  width: 100px;
}

.menu_wrapper .menu_container .nav_wrapper button {
  height: 50px;
  border-radius: 50px;
  border: none;
  width: 200px;
  color: #f50;
  background: #fff;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.back {
  background: #fff;
}

.back .menu_container {
  width: 70%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back .menu_container .logo_wrapper {
  -webkit-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
}

.back .menu_container .logo_wrapper .logo {
  content: url(/static/media/logob.40977002.svg);
  width: 100px;
  -webkit-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
}

.back .menu_container .nav_wrapper button {
  height: 50px;
  border-radius: 50px;
  border: none;
  width: 200px;
  color: #fff;
  background: #f50;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
}

@media only screen and (max-width: 600px) {
  .menu_container .nav_wrapper {
    display: none;
  }
}
/*# sourceMappingURL=style.css.map */
